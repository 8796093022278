import ViewInventory  from "./ViewInventory";
import { React, useState,useContext, useEffect } from "react";
import {Link } from "react-router-dom";
import logo from '../img/logo_white.png';
import main_logo from '../img/main_logo.webp';

import logoSmall from '../img/atto_desk_logo_icon_white.webp';
import $ from 'jquery';
import UserContext from './UserContext';

function SideNav(){

    const { username, userDetails } = useContext(UserContext);

    // useEffect(() => {
    //     console.log("UserProfile - username:", username); // Debugging log
    //     console.log("UserProfile - userDetails:", userDetails.user_role); // Debugging log
    //   }, [username, userDetails]);
    
    // function myFunction(x) {
    //     if (x.matches) {
    //       document.getElementById("left_panel").classList.add("menuHoverClass");
    //     } else {
    //       document.getElementById("left_panel").classList.remove("menuHoverClass");
    //     }
    //   }
    //   var x = window.matchMedia("(max-width: 1024px)");
    //   myFunction(x);
    //   x.addListener(myFunction);
      
      // -------------------------------
      // Active Menu Background
      // -------------------------------
      var li = $("#leftmenu li").click(function () {
        li.removeClass("vm_bg_secondary");
        $(this).addClass("vm_bg_secondary");
      });
      var ul_li = $("#leftmenu li ul li").click(function () {
        ul_li.removeClass("vm_bg_gray1");
        $(this).addClass("vm_bg_gray1");
      });

    return(
<div class="d-flex">
    <div class="left_panel position-fixed " id="left_panel">

    <Link to={'/login'}> 
        <div class="px-3 logo bg-dark align-items-center" id="mainlogo_big" style={{display: "flex"}}>
            <img width="120" src={main_logo} alt="Attodesk Logo Big"/>
        </div>
        <div class="pe-2 logo bg-dark justify-content-end align-items-center" id="mainlogo_small" style={{display: "none"}}>
            <img width="20" src={logoSmall} alt="Attodesk Logo Small"/>
        </div>
        </Link>
  


<div class="navigation" id="navigation">


<ul class="cd-accordion cd-accordion--animated margin-top-lg margin-bottom-lg ps-2" id="leftmenu">

            <li class="cd-accordion__item cd-accordion__item--has-children">
            <Link to={'/view-dashboard'}>
                    <div class="menu_item_desktop_expand">
                        <label class="d-flex justify-content-between w-100 cd-accordion__label cd-accordion__label--icon-folder px-2">
                            <div>
                                <i class="bi bi-speedometer2"></i>
                                <span>Dashboard</span>
                            </div>
                            {/* <div class="notification bg-danger text-white d-flex justify-content-center align-items-center">
                                3
                            </div> */}
                        </label>
                    </div>
       
                <label class="cd-accordion__label cd-accordion__label--icon-folder px-2 menu_item_desktop_collapsed" for="group-1">
                    <i class="bi bi-person-rolodex me-1"></i>
                </label>
                </Link>
            </li>
          
            <li class="cd-accordion__item cd-accordion__item--has-children">
            <Link to={'/view-customers'}>
                    <div class="menu_item_desktop_expand">
                        <label class="d-flex cd-accordion__label cd-accordion__label--icon-folder px-2">
                            <i class="bi bi-person"></i>
                            <span>Customer</span>
                        </label>
                    </div>
               
                <label class="cd-accordion__label cd-accordion__label--icon-folder px-2 menu_item_desktop_collapsed" for="group-1">
                    <i class="bi bi-person me-1"></i>
                </label>
                </Link>
            </li>

            {/* <li class="cd-accordion__item cd-accordion__item--has-children">
            <Link to={'/view-inventory'}>
                    <div class="menu_item_desktop_expand">
                        <label class="d-flex cd-accordion__label cd-accordion__label--icon-folder px-2">
                            <i class="bi bi-car-front-fill"></i>
                            <span>Inventory</span>
                        </label>
                    </div>
                
                <label class="cd-accordion__label cd-accordion__label--icon-folder px-2 menu_item_desktop_collapsed" for="group-1">
                    <i class="bi bi-car-front-fill me-1"></i>
                </label>
                </Link>
            </li> */}

            {/* <li class="cd-accordion__item cd-accordion__item--has-children">
            <Link to={'/leads-details'}>
                    <div class="menu_item_desktop_expand">
                        <label class="d-flex cd-accordion__label cd-accordion__label--icon-folder px-2">
                            <i class="bi bi-person-rolodex"></i>
                            <span>Leads</span>
                        </label>
                    </div>
              
                <label class="cd-accordion__label cd-accordion__label--icon-folder px-2 menu_item_desktop_collapsed" for="group-1">
                    <i class="bi bi-person-rolodex me-1"></i>
                </label>
                </Link>
            </li> */}

            <li class="cd-accordion__item cd-accordion__item--has-children">
            <Link to={'/bookings'}>
                    <div class="menu_item_desktop_expand">
                        <label class="d-flex cd-accordion__label cd-accordion__label--icon-folder px-2">
                            <i class="bi bi-book"></i>
                            <span>Bookings</span>
                        </label>
                    </div>
              
                <label class="cd-accordion__label cd-accordion__label--icon-folder px-2 menu_item_desktop_collapsed" for="group-1">
                    <i class="bi bi-person-book me-1"></i>
                </label>
                </Link>
            </li>


          
     

<li class="cd-accordion__item cd-accordion__item--has-children">
                    <Link to={'/view-booking'}>
                            <label class="cd-accordion__label cd-accordion__label--icon-folder menu_text" for="sub-group-1">
                                <div class="d-flex justify-content-between">
                                    <span>
                                        <i class="bi bi-building-check pe-1"></i> Manual Booking
                                    </span>
                                </div>
                            </label>
                            </Link>
                    </li> 

                      {userDetails.user_role === 'Coordinator' ? null :
            <><li class="cd-accordion__item cd-accordion__item--has-children">

                <Link to={'/viewallbookings'}>
                            <label class="cd-accordion__label cd-accordion__label--icon-folder menu_text" for="sub-group-1">
                                <div class="d-flex justify-content-between">
                                    <span>
                                        <i class="bi bi-calendar-check pe-1"></i> Calender
                                    </span>
                                </div>
                            </label>
                            </Link>
                 </li>


                 <li class="cd-accordion__item cd-accordion__item--has-children">
                                    <Link to={'/setting-game-location'}>
                                        <label class="cd-accordion__label cd-accordion__label--icon-folder menu_text" for="sub-group-1">
                                            <div class="d-flex justify-content-between">
                                                <span>
                                                    <i class="bi bi-pin-map pe-1"></i> Location
                                                </span>

                                            </div>
                                        </label>
                                    </Link>
                                </li>
                                {/* <li class="cd-accordion__item cd-accordion__item--has-children">
                                    <Link to={'/setting-game'}>
                                        <label class="cd-accordion__label cd-accordion__label--icon-folder menu_text" for="sub-group-1">
                                            <div class="d-flex justify-content-between">
                                                <span>
                                                    <i class="bi bi-controller pe-1"></i> Game
                                                </span>

                                            </div>
                                        </label>
                                    </Link>
                                </li> */}
                                <li class="cd-accordion__item cd-accordion__item--has-children">
                                    <Link to={'/setting-game-available-location'}>
                                        <label class="cd-accordion__label cd-accordion__label--icon-folder menu_text" for="sub-group-1">
                                            <div class="d-flex justify-content-between">
                                                <span>
                                                    <i class="bi bi-pin-map pe-1"></i> Available Location
                                                </span>

                                            </div>
                                        </label>
                                    </Link>
                                </li>
                               </>
}

                    <li class="cd-accordion__item cd-accordion__item--has-children">
                    <Link to={'/setting-create-games'}>
                            <label class="cd-accordion__label cd-accordion__label--icon-folder menu_text" for="sub-group-1">
                                <div class="d-flex justify-content-between">
                                    <span>
                                        <i class="bi bi-controller pe-1"></i> Game Type
                                    </span>
                                   
                                </div>
                            </label>
                   </Link>
                    </li>
                    {userDetails.user_role === 'Coordinator' ? null :
                    <><li class="cd-accordion__item cd-accordion__item--has-children">
                                <Link to={'/view-team'}>
                                    <div class="menu_item_desktop_expand">
                                        <label class="d-flex justify-content-between w-100 cd-accordion__label cd-accordion__label--icon-folder px-2">
                                            <div>
                                                <i class="bi bi-people"></i>
                                                <span>Team</span>
                                            </div>
                                        </label>
                                    </div>

                                    <label class="cd-accordion__label cd-accordion__label--icon-folder px-2 menu_item_desktop_collapsed" for="group-1">
                                        <i class="bi bi-person-rolodex me-1"></i>
                                    </label>
                                </Link>
                            </li>
                            

                            <li class="cd-accordion__item cd-accordion__item--has-children">
                                <Link to={'/viewTransactions'}>
                                    <div class="menu_item_desktop_expand">
                                        <label class="d-flex justify-content-between w-100 cd-accordion__label cd-accordion__label--icon-folder px-2">
                                            <div>
                                                <i class="bi bi-credit-card"></i>
                                                <span>Online Transactions</span>
                                            </div>
                                        </label>
                                    </div>

                                    <label class="cd-accordion__label cd-accordion__label--icon-folder px-2 menu_item_desktop_collapsed" for="group-1">
                                        <i class="bi bi-person-rolodex me-1"></i>
                                    </label>
                                </Link>
                            </li>
                            
                            
                            <li class="cd-accordion__item cd-accordion__item--has-children">
                                    <input class="cd-accordion__input" type="checkbox" name="group-1" id="group-1" />
                                    <a href="index.php">
                                        <label class="menu_item_desktop_expand cd-accordion__label cd-accordion__label--icon-folder px-2" for="group-1">
                                            <div class="d-flex">
                                                <i class="bi bi-gear"></i>
                                                <span>Settings</span>
                                            </div>
                                            <i class="bi bi-caret-down-fill"></i>
                                        </label>
                                    </a>
                                    <label class="cd-accordion__label cd-accordion__label--icon-folder px-2 menu_item_desktop_collapsed" for="group-1">
                                        <i class="bi bi-speedometer2 me-1"></i>
                                    </label>


                                    <ul class="cd-accordion__sub cd-accordion__sub--l1">



                                        <li class="cd-accordion__item cd-accordion__item--has-children">
                                            <Link to={'/setting-email-template'}>
                                                <label class="cd-accordion__label cd-accordion__label--icon-folder menu_text" for="sub-group-1">
                                                    <div class="d-flex justify-content-between">
                                                        <span>
                                                            <i class="bi bi-envelope pe-1"></i> Email
                                                        </span>

                                                    </div>
                                                </label>
                                            </Link>
                                        </li>

                                        <li class="cd-accordion__item cd-accordion__item--has-children">
                                            <Link to={'/setting-note-template'}>
                                                <label class="cd-accordion__label cd-accordion__label--icon-folder menu_text" for="sub-group-1">
                                                    <div class="d-flex justify-content-between">
                                                        <span>
                                                            <i class="bi bi-sticky pe-1"></i> Note
                                                        </span>
                                                    </div>
                                                </label>
                                            </Link>
                                        </li>

                                        {/* <li class="cd-accordion__item cd-accordion__item--has-children">
                                            <Link to={'/setting-sms-template'}>
                                                <label class="cd-accordion__label cd-accordion__label--icon-folder menu_text" for="sub-group-1">
                                                    <div class="d-flex justify-content-between">
                                                        <span>
                                                            <i class="bi bi-chat-right-dots pe-1"></i> SMS
                                                        </span>

                                                    </div>
                                                </label>
                                            </Link>
                                        </li>

                                        <li class="cd-accordion__item cd-accordion__item--has-children">
                                            <Link to={'/setting-pin-template'}>
                                                <label class="cd-accordion__label cd-accordion__label--icon-folder menu_text" for="sub-group-1">
                                                    <div class="d-flex justify-content-between">
                                                        <span>
                                                            <i class="bi bi-pin-map pe-1"></i> Pin
                                                        </span>
                                                    </div>
                                                </label>
                                            </Link>
                                        </li>
                                        <li class="cd-accordion__item cd-accordion__item--has-children">
                                            <Link to={'/setting-meeting-template'}>
                                                <label class="cd-accordion__label cd-accordion__label--icon-folder menu_text" for="sub-group-1">
                                                    <div class="d-flex justify-content-between">
                                                        <span>
                                                            <i class="bi bi-calendar-check pe-1"></i> Meeting
                                                        </span>
                                                    </div>
                                                </label>
                                            </Link>
                                        </li>

                                     

                                        <li class="cd-accordion__item cd-accordion__item--has-children">
                                            <Link to={'/setting-upload-template'}>
                                                <label cla ss="cd-accordion__label cd-accordion__label--icon-folder menu_text" for="sub-group-1">
                                                    <div class="d-flex justify-content-between">
                                                        <span>
                                                            <i class="bi bi-upload pe-1"></i> Upload
                                                        </span>
                                                    </div>
                                                </label>
                                            </Link>
                                        </li> */}



                                    </ul>
                                </li>
                                
                                <li class="cd-accordion__item cd-accordion__item--has-children">
                                <Link to={'/reports'}>
                                    <div class="menu_item_desktop_expand">
                                        <label class="d-flex justify-content-between w-100 cd-accordion__label cd-accordion__label--icon-folder px-2">
                                            <div>
                                                <i class="bi bi-file-earmark-text"></i>
                                                <span>Reports</span>
                                            </div>
                                        </label>
                                    </div>

                                    <label class="cd-accordion__label cd-accordion__label--icon-folder px-2 menu_item_desktop_collapsed" for="group-1">
                                        <i class="bi bi-person-rolodex me-1"></i>
                                    </label>
                                </Link>
                            </li>

                            <li class="cd-accordion__item cd-accordion__item--has-children">
                                <Link to={'/web-setting'}>
                                    <div class="menu_item_desktop_expand">
                                        <label class="d-flex justify-content-between w-100 cd-accordion__label cd-accordion__label--icon-folder px-2">
                                            <div>
                                                <i class="bi bi-gear"></i>
                                                <span>Web Setting</span>
                                            </div>
                                        </label>
                                    </div>

                                    <label class="cd-accordion__label cd-accordion__label--icon-folder px-2 menu_item_desktop_collapsed" for="group-1">
                                        <i class="bi bi-person-rolodex me-1"></i>
                                    </label>
                                </Link>
                            </li>
                                
                                
                                
                                
                                
                                
                                
                                </>



}


</ul>
  </div>

 </div>
 </div>
    );
}
export default SideNav;