import React, {useState,useEffect} from "react";
import './App.css';
import $ from 'jquery';
import ViewInventory from './components/ViewInventory';
import SideNav from './components/SideNav';
import TopBar from "./components/TopBar";
import TopBarInventory from "./components/TopBarInventory";
import {BrowserRouter as Router,Routes, Switch, Route, useNavigate} from 'react-router-dom'
import Inventory from './components/Inventory';
import EditInventory from './components/EditInventory';
import InventorySection2 from "./components/InventorySection2";
import InventorySection3 from "./components/InventorySection3";
import EditInventorySection2 from "./components/EditInventorySection2";
import EditInventorySection3 from "./components/EditInventorySection3";
import SettingInvoicePreset from "./components/SettingInvoicePreset";
import EditSettingInvoicePreset from "./components/EditSettingInvoicePreset";
import SettingImagePreset from "./components/SettingImagePreset";
import Login from "./components/Login";
import SettingImageEditing from "./components/SettingImageEditing";
import InventoryDetails from "./components/InventoryDetails";
import { UserProvider } from './components/UserContext';
import VehicleList from "./components/VehicleList";
import EmailLogs from "./components/EmailLogs";
import ViewCustomers from "./components/ViewCustomers";
import ViewCustomerDetails from "./components/ViewCustomerDetails";
import CreateCustomer from "./components/CreateCustomer";
import EditCustomer from "./components/EditCustomer";
import ViewLeads from "./components/ViewLeads";
import LeadsDetails from "./components/LeadsDetails";
import SettingEmailTemplate from "./components/SettingEmailTemplate";
import EditSettingEmailTemplate from "./components/EditSettingEmailTemplate";
import SettingSmsTemplate from "./components/SettingSmsTemplate";
import EditSettingSmsTemplate from "./components/EditSettingSmsTemplate";
import SettingNoteTemplate from "./components/SettingNoteTemplate";
import EditSettingNoteTemplate from "./components/EditSettingNoteTemplate";
import SettingPinTemplate from "./components/SettingPinTemplate";
import EditSettingPinTemplate from "./components/EditSettingPinTemplate";
import SettingMeetingTemplate from "./components/SettingMeetingTemplate";
import ViewDashboard from "./components/ViewDashboard";
import SettingUploadTemplate from "./components/SettingUploadTemplate";
import EditSettingUploadTemplate from "./components/EditSettingUploadTemplate";
import ViewTeam from "./components/ViewTeam";
import CreateTeamMember from "./components/CreateTeamMember";
import EditTeamMember from "./components/EditTeamMember";
import SettingGameType from "./components/SettingGameType";
import EditSettingGameType from "./components/EditSettingGameType";
import ViewCalender from "./components/ViewCalender";

import SettingGame from "./components/SettingGame";
import EditSettingGame from "./components/EditSettingGame";
import SettingGameLocation from "./components/SettingGameLocation";
import EditSettingGameLocation from "./components/EditSettingGameLocation";
import SettingGameAvailableLocation from "./components/SettingGameAvailableLocation";
import EditSettingGameAvailableLocation from "./components/EditSettingGameAvailableLocation";
import SettingGameException from "./components/SettingGameException";
import EditSettingGameException from "./components/EditSettingGameException";
import ViewBooking from "./components/ViewBooking";
import SettingGameMainPackage from "./components/SettingGameMainPackage";
import EditSettingGameMainPackage from "./components/EditSettingGameMainPackage";
import SettingGamePackage from "./components/SettingGamePackage";
import EditSettingGamePackage from "./components/EditSettingGamePackage";
// import SettingCreateGame from "./components/SettingCreateGame";
import SettingCreateGames from "./components/SettingCreateGames";
import ConsentForm from "./components/ConsentForm";
import MultipleConsentForms from "./components/MultipleConsentForms";
import ConsentFormPage from "./components/ConsentFormPage";
import ThankYouPage from './components/ThankYouPage';
import ThankYouCompleted from './components/ThankYouCompleted';
import KidConsentForm from './components/KidConsentForm';
import Bookings from './components/Bookings';
import ViewAllBookingsBookings from './components/ViewAllBookings';
import ViewTransactions from "./components/ViewTransactions";
import Reports from "./components/Reports";
import WebSetting from "./components/WebSetting";
import EditWebSetting from "./components/EditWebSetting";


// import Step1 from "./components/Step1";
// import Step2 from "./components/Step2";


function App() {


  useEffect(() => {
   
  }, []);
  
  return (
    <UserProvider>
    <Router >
    <div className="App">
    
      <Routes>


   <Route path='*' element={<Login />} /> 
   <Route path='/top-bar' element={<TopBar />} />
   <Route path='/top-bar-inventory' element={<TopBarInventory />} />

   <Route path='/view-dashboard' element={<ViewDashboard />} />

   <Route path='/view-inventory' element={<ViewInventory />} />
   <Route path='/inventory/:id' element={<Inventory />}/>
    <Route path='/inventory' element={<Inventory/>}/>
    <Route path='/edit-inventory/:id' element={<EditInventory />} />

    <Route path='/inventorySection2/:inventory_section_id' element={<InventorySection2 />} />
    <Route path='/edit-inventorySection2/:id' element={<EditInventorySection2 />} />

    <Route path='/inventorySection3/:inventory_section_id' element={<InventorySection3 />} />
    <Route path='/edit-inventorySection3/:inventory_section_id' element={<EditInventorySection3 />} />

    <Route path='/setting-invoice-preset' element={<SettingInvoicePreset />} />
    <Route path='/edit-invoice-preset/:id' element={<EditSettingInvoicePreset />} />

    <Route path='/setting-image-preset' element={<SettingImagePreset />} />

    <Route path='/setting-image-editing' element={<SettingImageEditing />} />

    <Route path='/setting-email-template' element={<SettingEmailTemplate />} />
    <Route path='/edit-email-template/:id' element={<EditSettingEmailTemplate />} />

    <Route path='/setting-sms-template' element={<SettingSmsTemplate />} />
    <Route path='/edit-sms-template/:id' element={<EditSettingSmsTemplate />} />

    <Route path='/view-calender' element={<ViewCalender />} />

    <Route path='/setting-note-template' element={<SettingNoteTemplate />} />
    <Route path='/edit-note-template/:id' element={<EditSettingNoteTemplate />} />

    <Route path='/setting-pin-template' element={<SettingPinTemplate />} />
    <Route path='/edit-pin-template/:id' element={<EditSettingPinTemplate />} />



    <Route path='/setting-upload-template' element={<SettingUploadTemplate />} />
    <Route path='/edit-upload-template/:id' element={<EditSettingUploadTemplate />} />

    <Route path='/inventory-details/:id' element={<InventoryDetails />} />
    <Route path='/vehicle-list' element={<VehicleList />} />
    <Route path='/email-logs' element={<EmailLogs />} />

    <Route path='/view-customers' element={<ViewCustomers />} />
    <Route path='/view-customer-details/:id' element={<ViewCustomerDetails />} />
    <Route path='/create-customer' element={<CreateCustomer />} />
    <Route path='/edit-customer/:id' element={<EditCustomer />} />

    <Route path='/view-leads' element={<ViewLeads />} />
    <Route path='/booking-details/:id' element={<LeadsDetails />} />
    <Route path='/booking-details' element={<LeadsDetails />} />

    <Route path='/view-team' element={<ViewTeam />} />
    <Route path='/create-team-member' element={<CreateTeamMember />} />
    <Route path='/edit-team-member/:id' element={<EditTeamMember />} />

    

    <Route path='/setting-game' element={<SettingGame />} />
    <Route path='/edit-game/:id' element={<EditSettingGame />} />

    <Route path='/setting-game-location' element={<SettingGameLocation />} />
    <Route path='/edit-game-location/:id' element={<EditSettingGameLocation />} />
    {/* <Route path='/edit-game-location/:location_slug' element={<EditSettingGameLocation />} /> */}

    <Route path='/setting-game-available-location' element={<SettingGameAvailableLocation />} />
    <Route path='/edit-game-available-location/:id' element={<EditSettingGameAvailableLocation />} />


    <Route path='/setting-game-type' element={<SettingGameType />} />
    <Route path='/edit-game-type/:id' element={<EditSettingGameType />} />

    
    <Route path='/setting-game-exception' element={<SettingGameException />} />
    <Route path='/edit-game-exception/:id' element={<EditSettingGameException />} />

    <Route path='/view-booking' element={<ViewBooking />} />

    <Route path='/setting-game-main-package' element={<SettingGameMainPackage />} />
    <Route path='/edit-game-main-package/:id' element={<EditSettingGameMainPackage />} />

    <Route path='/setting-game-package' element={<SettingGamePackage />} />
    <Route path='/edit-game-package/:id' element={<EditSettingGamePackage />} />

    {/* <Route path='/setting-create-game' element={<SettingCreateGame />} /> */}

    {/* <Route path='/step1' element={<Step1 />} />
    <Route path='/step2' element={<Step2 />} /> */}

      <Route path='/setting-create-games' element={<SettingCreateGames />} />

      <Route path='/consent-form' element={<ConsentForm />} />

      <Route path='/multiple-consent-form' element={<MultipleConsentForms />} />

      <Route path='/consent-form-page/:id' element={<ConsentFormPage />} />
      <Route path="/thank-you/:bookingId" element={<ThankYouPage />} />
      <Route path="/thank-you-completed/:bookingId" element={<ThankYouCompleted />} />
      <Route path="/kidsForm" element={<KidConsentForm />} />

      <Route path="/bookings" element={<Bookings />} />
      <Route path="/viewallbookings" element={<ViewAllBookingsBookings />} />
      <Route path="/viewTransactions" element={<ViewTransactions />} />

      <Route path="/reports" element={<Reports />} />

      <Route path="/web-setting" element={<WebSetting />} />
      <Route path='/edit-web-setting/:id' element={<EditWebSetting />} />

  </Routes>
 

    </div>
    </Router>
    </UserProvider>
  );
}

export default App;
