import logo from '../img/logo_white.png';
import backgroundImage from '../img/lasercombat_background.jpeg';
import Swal from 'sweetalert2';
import axios from 'axios';
import {Link, useParams, useNavigate} from "react-router-dom";
import { useState, useContext, useEffect } from "react";
import TopBar from './TopBar';
import UserContext from './UserContext';

function Login(){

    const API_URL = process.env.REACT_APP_API_URL;
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState('');
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");           
    const [error_list, setErrorList] = useState([]);
    const [usernameDisplay, setUsernameDisplay] = useState('');
    const userContext = useContext(UserContext);

    // const [login, setlogin] = useState([]);


    async function login(event){
        event.preventDefault();
        setLoading(true); 
    
          const formData = new FormData()
    
          formData.append('username', username);
          formData.append('password', password);
       
          axios.post(`${API_URL}/login-check`, formData).then(res=>{
            const user_id = res.data.login.id;
            const username = res.data.login.username;
               setUsername(res.data.login.username);
               userContext.setUsername(username);
            //console.log(user_id);
              //  navigate('/top-bar/'+username); 
              
                navigate('/view-dashboard');            
            }).catch(function(error) {
                
            //     if(error.response.status===401){         
            //     setErrorList(error.response.data.validate_err)
            //     }
            //    else{
                 Swal.fire({         
                   text:error.response.data.message,
                   icon:"error"
                 })
              // }
            setLoading(false); 
             })
         
}

    return(
        <div>
         
        

<section class="login bg-light vh-100 vm_bg_cover vm_bg_center vm_bg_norepeat" style={{background:`url(${backgroundImage})`}}>
    {/* <div class="overlay w-100 h-100 d-flex justify-content-center" style={{background: "linear-gradient(80deg, rgba(255, 255, 255, 0)0%, rgb(255, 255, 255) 100%)"}}> */}
    <div class="overlay w-100 h-100 d-flex justify-content-center" >
        <div class="container_login h-100 w-100"> 
            <div class="row d-flex justify-content-center align-items-center h-100">
                <div class="col-md-5"></div>
                <div class="col-md-4">
                    <div class="shadow-lg p-4 w-100 mx-auto rounded-box"  style={{ backgroundColor: 'rgba(255, 255, 255, 0.7)' }}>
                        <div class="d-flex justify-content-center pb-4">
                            <img src={logo} alt="Lasercombat Login" class="logo-img" style={{ maxWidth: '150px' }}       />
                        </div>
                   
                        <form >
                            <div class="form-floating mb-3 lh-base" style={{ width: '100%' }}>
                                <input type="text" class="form-control shadow-sm" name="username" value={username} id="username" 
                                onChange={(event) =>{ setUsername(event.target.value); }} placeholder="Username" style={{ width: '100%' }}/>
                                <label for="username">Username</label>
                                <span class="text-danger">{error_list.username}</span>
                            </div>
                            
                            <div class="form-floating mb-4 lh-base">
                                <input type="password" class="form-control shadow-sm"  name="password" value={password} id="password" 
                                 onChange={(event) =>{ setPassword(event.target.value); }} placeholder="Password"/>
                                <label for="password">Password</label>
                                <span class="text-danger">{error_list.password}</span>
                            </div>
                            
                            <button class="btn vm_bg_primary text-white" onClick={login}>{loading?'Loading...':'Login'}</button>
                        </form>

                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
            </div>
    )
}
export default Login;